<!-- 人脉 -->
<template>
  <div class="main">
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <ContactList :list="list" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getConnections } from '@/api/discover'
import ContactList from '@/components/extend/contactList'
export default {
  name: 'connection',
  components: { ContactList },
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getConnections(this.userId, this.page)
        .then(res => {
          this.loading = false
          this.pullRefresh = false
          this.$nextTick(() => {
            this.list.push(...res.list)
          })
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.error = true
        })
    }
  }
}
</script>

<style scoped>
</style>
