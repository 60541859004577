<template>
  <div>
    <div class="discover_head">
      <van-tabs
        v-model="active"
        color="#247dff"
        title-active-color="#247dff"
        sticky
        :offset-top="0"
      >
        <van-tab title="案例">
          <Case ref="case" />
        </van-tab>
        <van-tab title="人脉">
          <Connection ref="connection" />
        </van-tab>
        <van-tab title="资料">
          <Material ref="material" from="discover" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Connection from './discover/widget/connection'
import Case from './discover/widget/case'
import Material from './discover/widget/material'

export default {
  components: {
    Connection,
    Case,
    Material
  },
  data() {
    return {
      active: 0
    }
  },
  methods: {},
  mounted() {
    window.T = {}
    T.vm = this
  }
}
</script>

<style scoped>
/* .discover_head {
  height: 100%;
} */
</style>
