<!-- 案例 -->
<template>
  <div class="main">
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <div class="case_list" @click="goToDiscoverDetials(first.autoid)" v-if="first">
          <div class="case_list_titlle">
            <div>
              <img src="@/assets/images/jinr.png" class="toutiao" />
            </div>
            <div class="title_wenzi">{{first.title}}</div>
          </div>
          <div class="case_list_cneter">
            <div class="case_list_cneter_img">
              <img :src="first.pic" alt />
            </div>
            <div class="case_list_cneter_details">{{first.describe}}</div>
          </div>
          <div class="browse_box">
            <div>{{first.crdate}}</div>
            <div>{{first.readnum||0}} 浏览</div>
          </div>
        </div>
        <div
          class="case_list_type"
          v-for="(item,i) in list"
          :key="'case'+i"
          @click="goToDiscoverDetials(item.autoid)"
        >
          <div class="case_list_type_img">
            <img :src="item.pic" alt />
          </div>
          <div class="case_list_type_right">
            <div class="name_jinr">{{item.title}}</div>
            <div class="name_time_jinr">{{item.crdate}}</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getCaseList } from '@/api/discover'

export default {
  name: 'case',
  data() {
    return {
      page: 1, // 当前页数
      list: [], // 显示的数据
      first: null, // 第一条数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.first = null
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getCaseList(this.page)
        .then(res => {
          let list = res.list
          this.loading = false
          // 截取第一条数据
          if (!this.first) {
            this.first = list.shift()
            this.list.push(...list)
          } else {
            this.list.push(...res.list)
          }
          this.page += 1
          this.pullRefresh = false
          if (!res.list.length || res.list.length < 10) {
            this.finished = true
          }
        })
        .catch(err => {
          this.pullRefresh = false
          this.error = true
        })
    },
    // -> 案例详情
    goToDiscoverDetials(id) {
      this.$router.push({
        path: '/discover_details/' + id
      })
    }
  }
}
</script>

<style scoped>
.name_time_jinr {
  font-size: 2.933vw;
  color: #909399;
}

.name_jinr {
  font-size: 4vw;
  color: #303133;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 6vw;
}

.case_list_type_right {
  width: 57.067vw;
  height: 22.133vw;
  margin-left: 2.4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.case_list_type_img {
  width: 33.333vw;
  height: 22.133vw;
  border-radius: 4%;
  background-color: #c6c8cb;
}

.case_list_type_img img {
  width: 100%;
  height: 100%;
  border-radius: 4%;
  object-fit: cover;
}

.case_list_type {
  width: 93.6vw;
  height: 28.8vw;
  border-bottom: 0.233vw solid #edeff2;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.browse_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2.933vw;
  color: #909399;
  margin-top: 3vw;
}

.case_list_cneter_details {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-top: 2.267vw;
}

.case_list_cneter_img {
  width: 93.6vw;
  height: 62.4vw;
  background-color: #c6c8cb;
  border-radius: 0.8vw;
}
.case_list_cneter_img > img {
  width: 100%;
  height: 100%;
  border-radius: 0.8vw;
  object-fit: cover;
}

.title_wenzi {
  font-size: 4.2vw;
  font-weight: bold;
  color: #303133;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 2.8vw;
}

.toutiao {
  display: block;
  width: 15.733vw;
  height: 3.733vw;
}

.case_list_titlle {
  height: 6vw;
  display: flex;
  align-items: center;
}

.case_list {
  width: 93.6vw;
  border-bottom: 0.233vw solid #edeff2;
  margin: 2.933vw auto;
}
</style>
